import React, { useEffect } from "react"
// import DataEngineering from "../components/DataEngineering"

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
const DataEngineering = React.lazy(() => import("../components/DataEngineering"))

export default function ServicesPage() {
 
  
  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/data-engineering/" />
      </Helmet>
        <React.Suspense fallback={<div />}>
          <DataEngineering />
        </React.Suspense>
 
    </Layout>
  )
}
